<template>
  <v-img :height="maxHeight" :max-height="maxHeight" :width="width ? width: ''" contain eager :src="src" />
</template>

<script>
export default {
  props: {
    src: String,
    maxHeight: {
      type: [String, Number],
      default: "65",
    },
    width: {
      type: [String, Number],
      default: ''
    }
  },
};
</script>
